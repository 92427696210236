/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/swiper@5.4.5/js/swiper.min.js
 * - /npm/lazysizes@5.3.2/lazysizes.min.js
 * - /npm/lazysizes@5.3.2/plugins/aspectratio/ls.aspectratio.min.js
 * - /npm/lazysizes@5.3.2/plugins/unveilhooks/ls.unveilhooks.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
